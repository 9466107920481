<template>
    <v-list dense>
        <v-subheader class="text-overline primary--text">{{ headerTitle }}</v-subheader>
        <v-list-item style="min-height: 20px" v-for="(item, idx) in info" :key="idx">
            <v-list-item-content class="secondary--text py-0">
                <v-list-item-title style="white-space: normal;" class="body-2">
                    <span class="subtitle-2 font-weight-bold">{{ item.label }}</span>
                    <span>{{ values[idx]  }}</span>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
    export default {
        props: {
            headerTitle: String,
            info: {
                type: Array,
                default: () => []
            },
            values: {
                type: Array,
                default: () => []
            }
        },
    }
</script>